import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import ClassSearch from "app/components/Algolia/ClassSearch";
import LoaderCentered from "app/components/Loader/LoaderCentered";
import { LIBRARY_MODULE, ANALYTICS_LOCATION } from "constants/index";
import { useFamilyModeSetting } from "hooks/useFamilyModeSetting";
import { useFamilyFriendlyFlag } from "hooks/useFeatureFlags";
import { libraryViewScreen, AccountMode } from "services/typewriter/segment";
import { useResetInitialPagination } from "hooks/useResetInitialPagination";
import { AccessType } from "services/graphql";
import { LibraryContainer } from "../LibraryContainer";
import {
  useClearSearchParamsForFamilyMode,
  useForceRerenderOnModeSwitch,
} from "../hooks";

export const FreeClasses = () => {
  const isFamilyFeatureEnabled = useFamilyFriendlyFlag();

  const { resettingPagination } = useResetInitialPagination();
  const { clearingSearchForFamilyMode } = useClearSearchParamsForFamilyMode();
  const { rerenderingOnModeSwitch } = useForceRerenderOnModeSwitch();

  useLibraryViewScreenEvent();

  if (
    clearingSearchForFamilyMode ||
    rerenderingOnModeSwitch ||
    resettingPagination
  ) {
    return <LoaderCentered />;
  }

  return (
    <>
      <Helmet title="Free Classes | Online Dance Classes and Tutorials" />
      <LibraryContainer>
        <ClassSearch
          isFamilyFeatureEnabled={isFamilyFeatureEnabled}
          refinements={{ accessType: [AccessType.Free] }}
        />
      </LibraryContainer>
    </>
  );
};

function useLibraryViewScreenEvent() {
  const { isFamilyModeOn, loadingIsFamilyModeOn } = useFamilyModeSetting();

  useEffect(() => {
    if (loadingIsFamilyModeOn) {
      return;
    }

    libraryViewScreen({
      screen_name: "Library - Free Classes",
      location: ANALYTICS_LOCATION.library,
      module: LIBRARY_MODULE.library_free_classes,
      account_mode: isFamilyModeOn ? AccountMode.Family : AccountMode.Studio,
    });
  }, [loadingIsFamilyModeOn, isFamilyModeOn]);
}
